import { useEffect, useState } from "react";
import styles from "./Toolbar.module.scss";
import store from "../../../services/store.service";
import { toggle } from "../../../services/store/backpack/backpack.store";
import { useSelector } from "react-redux";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { socketConnection } from "../../../core/api/common";
import { setTempQuickItemPane } from "../../../services/store/other/other.store";
import { ItemCell } from "../base/ItemsPager/ItemsPager";
import { MP_ICON, HP_ICON, TG_ICON } from "../../../services/base/constants";
import TgCoin from "../base/TgCoin/TgCoin";

function QuickPanel() {
  return (
    <div className={styles.quickPanelSelect}>
      <FontAwesomeIcon icon={faPlus} />
    </div>
  );
}

function Toolbar() {
  const [canSelectQuickItem, changeSelectQuickItem]: any = useState(false);
  const backpack = useSelector((store: any) => store.backpack);
  const other = useSelector((store: any) => store.other);
  const { config, params } = useSelector((store: any) => store.user);
  const [socket]: any = useState(socketConnection());

  useEffect(() => {
    if (other.tempQuikItem === null) return;
    changeSelectQuickItem(true);
  }, [other.tempQuikItem]);

  function changeQuickItem(itemIdx: number) {
    socket.emit("set quick pane item", {
      inventoryId: other.tempQuikItem,
      quickItemId: itemIdx,
    });
    store.dispatch(setTempQuickItemPane(null));
    changeSelectQuickItem(false);
  }

  return (
    <div className={styles.Toolbar}>
      <div className={styles.AdditionalInfo}>
        <div
          className={styles.HpIcon}
          dangerouslySetInnerHTML={{ __html: HP_ICON }}
        ></div>
        <div
          className={styles.MpIcon}
          dangerouslySetInnerHTML={{ __html: MP_ICON }}
        ></div>
        <div className={styles.hp}>
          <div className={styles.valueText}>
            {params.hp}/{params.maxhp}
          </div>
          <div
            className={styles.current}
            style={{
              width: `${(params.hp / params.maxhp) * 100}%`,
            }}
          ></div>
        </div>
        <div className={styles.mp}>
          <div className={styles.valueText}>
            {params.mp}/{params.maxmp}
          </div>
          <div
            className={styles.current}
            style={{
              width: `${(params.mp / params.maxmp) * 100}%`,
            }}
          ></div>
        </div>
        <div className={styles.exp}>
          <div className={styles.currentExp}>
            <div>
              XP {params.exp}/{params.nextLevel}
            </div>
            <div>LVL {params.level}</div>
          </div>
          <div
            className={styles.current}
            style={{
              width: `${(params.exp / params.nextLevel) * 100}%`,
            }}
          ></div>
        </div>
        <div className={styles.gold}>
          <TgCoin size="sm" />
          {/* <div className={styles.count}>{params.gold}</div>
          <div
            className={styles.coin}
            dangerouslySetInnerHTML={{ __html: TG_ICON }}
            id="toolbar-coin"
          ></div> */}
        </div>
      </div>
      <div className={styles.Items + " ItemsList"}>
        <div
          className="Item"
          onClick={() => {
            if (canSelectQuickItem) {
              changeQuickItem(0);
              return;
            }
            socket.emit("use item", config.quickItemFirst);
          }}
        >
          {canSelectQuickItem ? <QuickPanel /> : null}
          {canSelectQuickItem ? null : (
            <ItemCell
              item={params.items[config.quickItemFirst]}
              withLevel={true}
            />
          )}
        </div>
        <div
          className="Item"
          onClick={() => {
            if (canSelectQuickItem) {
              changeQuickItem(1);
              return;
            }
            socket.emit("use item", config.quickItemSecond);
          }}
        >
          {canSelectQuickItem ? <QuickPanel /> : null}
          {canSelectQuickItem ? null : (
            <ItemCell
              item={params.items[config.quickItemSecond]}
              withLevel={true}
            />
          )}
        </div>
      </div>
      <div
        className={`${styles.Backpack} BtnWithPressed ${
          backpack.isOpened ? styles.OpenedBackpack : ""
        }`}
        id="toolbar-backpack"
        onClick={() => {
          store.dispatch(toggle(!backpack.isOpened));
        }}
      ></div>
    </div>
  );
}

export default Toolbar;
