import { useEffect, useRef, useState } from "react";
import styles from "./Mob.module.scss";

import React from "react";

import AnimationAttack from "../animationAttack/AnimationAttack";
import { random } from "../../../services/base/list";
import ExpAnimate from "../expAnimate/expAnimate";
import { socketConnection } from "../../../core/api/common";
import { BOSS_PREFIX, MOB_PREFIX } from "../../../services/base/constants";
import ItemDrop from "../base/ItemDrop/ItemDrop";

function Boss({ params }: any) {
  const [socket]: any = useState(socketConnection());
  const [stylesOfMob, changeStyles]: any = useState({});
  const [mobId, setMobId]: any = useState("");

  useEffect(() => {
    const timings = 1.45 + "s";

    setMobId(`${BOSS_PREFIX}${params.posId}`);
    changeStyles({
      attack: {
        animation: `animate-boss-attack-${params.id} ${timings} infinite`,
      },
      die: {
        animation: `animate-boss-die-${params.id} 2.6s`,
      },
      skill: {
        animation: `animate-boss-skill-${params.id} ${timings} infinite`,
      },
      stay: { animation: `animate-boss-stay-${params.id} ${timings} infinite` },
    });
  }, []);

  return (
    <>
      <div
        className={`${styles.BossConainer} ${
          params.state === "die" ? styles.BossDied : ""
        }`}
        id={mobId}
        onPointerDown={$event => {
          // Вызывает панель
          if (params.state === "die") {
            // drawCoin();
          } else {
            socket.send("attack mob", params.targetId);
          }
        }}
      >
        {params.damages[params.damages.length - 1]?.isKilled ? (
          <ExpAnimate value={params.damages[params.damages.length - 1]?.exp} />
        ) : null}

        {params.state !== "die" && (
          <div className={styles.BossName}>{params.name}</div>
        )}

        <div
          key={params.state}
          className={`${styles.Boss}`}
          style={stylesOfMob[params.state]}
        ></div>

        <div className={styles.Drop}>
          {params.damages[params.damages.length - 1]?.isKilled ? (
            <ItemDrop
              items={params.damages[params.damages.length - 1]?.items}
            />
          ) : null}
        </div>

        <div className={styles.BossAttackAnimate}>
          {params.damages.map((damageItem: any, idx: number) => {
            return (
              <AnimationAttack
                key={idx}
                type={damageItem.type}
                value={damageItem.value}
              />
            );
          })}
        </div>
      </div>
    </>
  );
}

export default Boss;
