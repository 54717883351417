import React from "react";
import { useEffect, useRef, useState } from "react";
import { random } from "../../../services/base/list";
import styles from "./expAnimate.module.scss";

function expAnimate({ value }: any) {
  const [stylesOfAttack, changeStyles] = useState("");

  const [showComponent, changeShowComponent] = useState(true);

  useEffect(() => {
    const timeout: any = setTimeout(() => {
      changeStyles(`translateY(${0}px)`);
    }, 100);

    setTimeout(() => {
      changeShowComponent(false);
    }, 2050);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  return (
    <>
      {!showComponent ? null : (
        <div className={styles.expAnimate}>
          <span
            className={`${styles.expAnimateValue}`}
            style={{ transform: stylesOfAttack }}
          >
            {value} EXP
          </span>
        </div>
      )}
    </>
  );
}

export default React.memo(expAnimate);
