import { request } from "../common";
import { API_GROUP_INFO, API_GROUP_RATE } from "../constants";

export const customUserApi = {
  getGroupInfo: async () => {
    return await request({
      method: "GET",
      url: `${API_GROUP_INFO}`,
      withCredentials: true,
    });
  },
  getGroupRate: async (params: any) => {
    return await request({
      method: "GET",
      params,
      url: `${API_GROUP_RATE}`,
      withCredentials: true,
    });
  },
};

export const { getGroupInfo, getGroupRate } = customUserApi;
