import { faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import styles from "./UnlockItemsModal.module.scss";
import { socketConnection } from "../../../../core/api/common";
import React from "react";

function UnlockItemsModal({ item, onClose }: any) {
  const [socket]: any = useState(socketConnection());

  useEffect(() => {
    //
  }, []);

  return (
    <div className={styles.ImproveItemContainer}>
      <div className={styles.ImproveItem}>
        <div
          className={"close-modal BtnWithPressed"}
          onClick={() => {
            onClose();
          }}
        ></div>
        <h2 className="my-0 white-text">Разблокировать ячейки?</h2>

        <div className={`white-text ${styles.WarnDescription} my-4`}>
          <p className={`mt-0 mb-0 ${styles.WarnDescriptionText}`}>
            Разблокировать дополнительно 5 ячеек?
            <br />
            Стоимость <span className="bold">1 TON</span>
          </p>
        </div>

        <div
          className={`${styles.ImproveBtn} BtnWithPressed`}
          onClick={() => {
            socket.send("unlock backpack cells", item);
          }}
        >
          Разблокировать
        </div>
      </div>
    </div>
  );
}

export default React.memo(UnlockItemsModal);
