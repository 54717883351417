import { useEffect, useState } from "react";
import styles from "./Backpack.module.scss";
import { toggle } from "../../../services/store/backpack/backpack.store";
import { useSelector } from "react-redux";
import store from "../../../services/store.service";
// import ItemsPager from "../base/ItemsPager/ItemsPager";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCoins,
  faHandBackFist,
  faPlus,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import ItemsPager, { ItemCell } from "../base/ItemsPager/ItemsPager";
import { IRequestResponse, socketConnection } from "../../../core/api/common";
import ItemInfo from "../base/ItemInfo/ItemInfo";
import { parseItem } from "../../../services/base/item";
import Effects from "../base/Effects/Effects";
import UnlockItemsModal from "../base/UnlockItemsModal/UnlockItemsModal";

function Backpack() {
  const { params } = useSelector((store: any) => store.user);
  const [unlockItemsModal, changeUnlockItemsModal]: any = useState(false);

  const [socket]: any = useState(socketConnection());

  useEffect(() => {
    //
  }, []);

  const BTN_PARAMS_ICON = (type: string) => {
    return params.abilityPoints ? (
      <div
        className={styles.ParamsButton}
        onClick={() => {
          socket.send("improve params by type", type);
        }}
      >
        <FontAwesomeIcon icon={faPlus} size="lg" />
      </div>
    ) : (
      ""
    );
  };

  return (
    <div className={`${styles.Backpack} bkg-modal`}>
      <div className={styles.Characteristic}>
        <div className={`${styles.Level} ${styles.Params}`}>
          Уровень {params.level}
        </div>
        <div className={styles.Params}>
          Здоровье <span> {params.hp}</span>
        </div>
        <div className={styles.Params}>
          Мана <span> {params.mp}</span>
        </div>
        {[
          { attrName: "Сила", type: "strength" },
          { attrName: "Ловкость", type: "agility" },
          { attrName: "Интеллект", type: "intelligence" },
        ].map(({ attrName, type }) => {
          return (
            <div key={type} className={styles.Params}>
              {attrName}
              <span>
                {params[type]}
                {BTN_PARAMS_ICON(type)}
              </span>
            </div>
          );
        })}
        <div className={styles.Params}>
          Защита <span>{params.defence}</span>
        </div>
        <div className={styles.Params}>
          Очки опыта <span>{params.abilityPoints}</span>
        </div>
        <div className={styles.Params}>
          <Effects />
        </div>
      </div>

      <div className={styles.Items}>
        {[
          { cx: `${styles.Head}`, type: "head" },
          { cx: `${styles.Cloak}`, type: "cloak" },
          { cx: `${styles.Necklace}`, type: "necklace" },
          { cx: `${styles.Armour}`, type: "armour" },
          { cx: `${styles.Belt}`, type: "belt" },
          { cx: `${styles.Shield}`, type: "shield" },
          { cx: `${styles.Weapon}`, type: "weapon" },
          { cx: `${styles.Gloves}`, type: "gloves" },
          { cx: `${styles.Legs}`, type: "legs" },
          { cx: `${styles.Ring} ${styles.RingOne}`, type: "ringOne" },
          { cx: `${styles.Ring} ${styles.RingTwo}`, type: "ringTwo" },
        ].map((element: any, idx: number) => {
          const hasItem = params.equipped[element.type] !== null;
          const [itemId, level, count] = parseItem(
            params.equipped[element.type],
          );

          // console.log(params.equipped[element.type], element.type);
          return (
            <div
              key={idx}
              className={`${styles.Item} ${element.cx} ${
                hasItem ? styles.Empty : null
              }`}
              onClick={() => {
                if (!hasItem) return;
                socket.send("take out item", element.type);
              }}
            >
              {hasItem && (
                <ItemCell
                  item={params.equipped[element.type]}
                  withCount={false}
                  withLevel={true}
                />
              )}
            </div>
          );
        })}
      </div>

      <div className={styles.ItemsPager}>
        <ItemsPager
          items={params.items}
          maxCount={105}
          onClickUnlock={() => {
            changeUnlockItemsModal(true);
          }}
        />
      </div>
      {unlockItemsModal ? (
        <UnlockItemsModal
          onClose={() => {
            changeUnlockItemsModal(false);
          }}
        />
      ) : null}
    </div>
  );
}

export default Backpack;
