import { useEffect, useRef, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import styles from "./CoinDrop.module.scss";
import coinImage from "../../../../images/components/interface/game-panel/coin.png";
import store from "../../../../services/store.service";

class DropCoinInterface {
  options: any;
  SVG_URI: string;

  svgEl: any;

  constructor() {
    this.SVG_URI = "http://www.w3.org/2000/svg";

    this.options = {
      history: [],
      targetId: "toolbar-backpack",
    };
  }

  add(positions: any, ref: any) {
    ref.current.appendChild(this.drawCurveLine(positions));
  }

  drawCurveLine(positions: any) {
    const group = document.createElementNS(this.SVG_URI, "g");
    const coin = document.createElementNS(this.SVG_URI, "image");
    const time = getRandomArbitrary(0.8, 1.7) + "s";

    const animateMotionOfCoin: any = document.createElementNS(
      this.SVG_URI,
      "animateMotion",
    );

    const toolbarCoinPos: any = document
      .getElementById(this.options.targetId)
      ?.getBoundingClientRect();
    const offsetPath = `M${positions.x - 5} ${positions.y} Q${
      positions.x + getRandomArbitrary(-165, 165)
    } ${positions.y} ${330} ${toolbarCoinPos.y + 60}`;

    coin.setAttribute("href", coinImage);
    coin.setAttribute("width", "9");
    coin.setAttribute("height", "9");
    coin.setAttribute("opacity", "0");

    animateMotionOfCoin.setAttribute("path", offsetPath);
    animateMotionOfCoin.setAttribute("dur", time);
    animateMotionOfCoin.setAttribute("begin", "0s");
    animateMotionOfCoin.setAttribute("calcMode", "spline");

    animateMotionOfCoin.setAttribute("keyTimes", "0;1");
    animateMotionOfCoin.setAttribute("keySplines", "0.50, 0, 0.67, 0");

    setTimeout(() => {
      // ON END
      const text = document.createElementNS(this.SVG_URI, "text");
      text.setAttribute("x", "320");
      text.setAttribute("y", `${toolbarCoinPos.y + 50}`);
      group.setAttribute("class", styles.CountOfCoin);
      text.textContent = `+${getRandomArbitrary(1, 23).toFixed(0)}`;
      group.innerHTML = "";
      group.appendChild(text);
      setTimeout(() => {
        // Remove element from DOM
        group.remove();
      }, 1700);
    }, Number(time.replace("s", "")) * 1000 - 50);

    coin.appendChild(animateMotionOfCoin);
    group.appendChild(coin);

    setTimeout(() => {
      coin.setAttribute("opacity", "1");
      animateMotionOfCoin.beginElement();
    }, 0);
    return group;
  }

  createSvgElement() {
    const SVG = document.createElementNS(this.SVG_URI, "svg");

    SVG.setAttribute("class", styles.CoinSVG);
    SVG.setAttribute("xmlns", this.SVG_URI);

    this.svgEl = SVG;
  }
}

const coinDropInterface = new DropCoinInterface();

function CoinDrop() {
  const elRef: any = useRef();

  useEffect(() => {
    let latestMobKilled: any = {};

    store.subscribe(() => {
      const { mobKilled }: any = store.getState().other;
      if (latestMobKilled.mobId !== mobKilled.mobId) {
        latestMobKilled = mobKilled;
        coinDropInterface.add(mobKilled, elRef);
      }
    });
  }, []);

  return (
    <svg
      ref={elRef}
      className={styles.CoinSVG}
      xmlns="http://www.w3.org/2000/svg"
    ></svg>
  );
}

export default CoinDrop;

function getRandomArbitrary(min: any, max: any) {
  return Math.random() * (max - min) + min;
}
