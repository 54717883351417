import { faDungeon, faLock } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import styles from "./SelectDungeonLevel.module.scss";
import React from "react";
import { getFromCache } from "../../../../services/base/list";
import { useSelector } from "react-redux";
import FullModal from "../FullModal/FullModal";

const ICONS = [
  { icon: <FontAwesomeIcon icon={faDungeon} />, type: "dungeon" },
  { icon: <FontAwesomeIcon icon={faLock} />, type: "lock" },
  { icon: <FontAwesomeIcon icon={faLock} />, type: "lock" },
  { icon: <FontAwesomeIcon icon={faLock} />, type: "lock" },
  { icon: <FontAwesomeIcon icon={faLock} />, type: "lock" },
  { icon: <FontAwesomeIcon icon={faLock} />, type: "lock" },
];

function SelectDungeonLevel({ onClose, onSelectedMap }: any) {
  const [activeType, changeActiveType]: any = useState("dungeon");
  const [dungeons, changeDungeons]: any = useState(null);
  const [isAnimating, setIsAnimating]: any = useState({});
  const { params } = useSelector((store: any) => store.user);

  const handleAnimation = (lvl: number) => {
    setIsAnimating((old: any) => {
      old[lvl] = true;
      return old;
    });
  };

  const handleAnimationEnd = (lvl: number) => {
    setIsAnimating((old: any) => {
      old[lvl] = false;
      return old;
    });
  };

  useEffect(() => {
    changeDungeons(getFromCache("dungeons"));
  }, []);

  return (
    <FullModal
      buttons={{
        active: activeType,
        list: ICONS,
        onClick: (type: string) => {
          if (type === "lock") return;
          changeActiveType(type);
        },
      }}
      onClose={() => {
        onClose();
      }}
      title="Выбор уровня"
    >
      <div className={`ItemsList ${styles.DungeonList}`}>
        {dungeons
          ? Object.values(dungeons).map(({ lvl }: any) => {
              return (
                <div
                  key={lvl}
                  className={`${styles.DungeonItem}`}
                  onClick={() => {
                    if (lvl <= params.dungeonLevel) {
                      onSelectedMap(lvl);
                    } else {
                      handleAnimation(lvl);
                    }
                  }}
                >
                  <div className={`${styles.DungeonItemLvl} Item`}>{lvl}</div>
                  {lvl <= params.dungeonLevel ? null : (
                    <div className={`${styles.DungeonItemLock}`}>
                      <div
                        className={`${
                          isAnimating[lvl] ? styles.DungeonItemLockAnimate : ""
                        }`}
                        onAnimationEnd={() => {
                          handleAnimationEnd(lvl);
                        }}
                      >
                        <FontAwesomeIcon icon={faLock} size="2xl" />
                      </div>
                    </div>
                  )}
                </div>
              );
            })
          : null}
      </div>
    </FullModal>
  );
}

export default React.memo(SelectDungeonLevel);
