import { request } from "../common";
import { API_ITEM_LIST } from "../constants";

export const customUserApi = {
  getList: async () => {
    return await request({
      data: null,
      method: "GET",
      url: `${API_ITEM_LIST}`,
      withCredentials: true,
    });
  },
};

export const { getList } = customUserApi;
