import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import styles from "./DialogTrade.module.scss";
import FullModal from "../../FullModal/FullModal";
import { ICON_SHOPS } from "../../../../../services/base/constants";
import DialogTradeBuy from "./Buy";
import store from "../../../../../services/store.service";
import { setCurrentTradeStep } from "../../../../../services/store/modal/modal.store";

const { armour, coat, gloves, legs, more, weapon } = ICON_SHOPS;

function DialogTradeAction({ onClose, type }: any) {
  const { tradeStep } = useSelector((store: any) => store.modal);

  const SHOP_TYPES: any = {
    "trade.buy": {
      icons: [
        {
          icon: <span dangerouslySetInnerHTML={{ __html: weapon }} />,
          type: "weapon",
        },
        {
          icon: <span dangerouslySetInnerHTML={{ __html: gloves }} />,
          type: "gloves",
        },
        {
          icon: <span dangerouslySetInnerHTML={{ __html: legs }} />,
          type: "legs",
        },
        {
          icon: <span dangerouslySetInnerHTML={{ __html: coat }} />,
          type: "coat",
        },

        {
          icon: <span dangerouslySetInnerHTML={{ __html: armour }} />,
          type: "armour",
        },
        {
          icon: <span dangerouslySetInnerHTML={{ __html: more }} />,
          type: "more",
        },
      ],
      title: "Купить",
    },
    "trade.sell": { icons: [], title: "Продать" },
    "trade.uniq": { icons: [], title: "Игровой магазин" },
  };

  const [activeType, changeActiveType] = useState(
    SHOP_TYPES[type]?.icons[0]?.type,
  );

  return (
    <>
      <FullModal
        buttons={{
          active: activeType,
          list: SHOP_TYPES[type].icons,
          onClick: (type: string) => {
            changeActiveType(type);
          },
        }}
        onClose={() => {
          if (tradeStep === 0) {
            onClose();
          } else {
            store.dispatch(setCurrentTradeStep(tradeStep - 1));
          }
        }}
        title={SHOP_TYPES[type].title}
      >
        {type === "trade.buy" && <DialogTradeBuy type={activeType} />}
      </FullModal>
    </>
  );
}

export default DialogTradeAction;
