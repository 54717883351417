import { useEffect, useState } from "react";
import styles from "./Map.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleLeft,
  faAngleRight,
  faCircleInfo,
  faCircleLeft,
  faPlus,
  faStar,
  faUser,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";
import {
  IRequestResponse,
  socketConnection,
} from "../../../../core/api/common";
import { getGroupRate } from "../../../../core/api/group/group.api";
import TimeEnd from "../TimeEnd/TimeEnd";
import { getFromCache } from "../../../../services/base/list";
import { useSelector } from "react-redux";
import { getCssImagePath } from "../../../../scss/styles";
import { setIsModalOpenedState } from "../../../../services/store/other/other.store";
import store from "../../../../services/store.service";

const mapLevels: any = {
  "0": {
    image: getCssImagePath("components/game-location/map/forest-1.webp"),
    name: "Волшебный лес",
  },
  "1": {
    image: getCssImagePath("components/game-location/map/evil-1.webp"),
    name: "Склеп",
  },
  "2": {
    image: getCssImagePath("components/game-location/map/desert-1.webp"),
    name: "Пустыня",
  },
  "3": {
    image: getCssImagePath("components/game-location/map/castle-1.webp"),
    name: "Замок",
  },
};

function MapAddUser({ user }: any) {
  const [socket]: any = useState(socketConnection());

  return (
    <div
      className={`${styles.MapStartPanelAddUser} ${
        user ? styles.AddUserSet : ""
      }`}
      onClick={() => {
        if (user) return;
        socket.emit("send message", { type: "dungeon" });
      }}
    >
      {user ? (
        <>
          <div className={styles.MapStartUserName}>{user.username}</div>
          <div className={styles.MapStartUserRate}>{user.rate}</div>
          <div className={`${styles.MapStartUserClose} Close`}></div>
        </>
      ) : (
        <FontAwesomeIcon icon={faPlus} />
      )}
    </div>
  );
}

function MapRate({ levelId, onClose }: any) {
  const [rateData, changeRateData]: any = useState(null);
  const [rateType, changeRateType]: any = useState("single");

  useEffect(() => {
    getGroupRate({ levelId }).then((resp: IRequestResponse) => {
      if (resp.hasError) return;
      const rateData = resp.getData();
      changeRateData(rateData);
    });
  }, []);

  return (
    <div className={styles.RateCotainer}>
      <h1>Рейтинг</h1>
      <table className={styles.RateTable}>
        <colgroup>
          <col width="10%" />
          <col width="30%" />
          <col width="35%" />
          <col width="25%" />
        </colgroup>
        <thead>
          <tr>
            <th>#</th>
            <th>Имя</th>
            <th>Время</th>
            <th>Урон</th>
          </tr>
        </thead>
        <tbody>
          {((rateData && rateData.rates && rateData.rates[rateType]) || []).map(
            (rateItem: any, index: number) => {
              return (
                <tr key={index}>
                  <td>{index + 1}.</td>
                  <td>{rateItem.login}</td>
                  <td>
                    <TimeEnd showMs={true} time={rateItem.time} />
                  </td>
                  <td>{rateItem.damage}</td>
                </tr>
              );
            },
          )}
          {rateData &&
          rateData.userPos &&
          rateData.userPos[rateType] &&
          rateData.userPos[rateType].position > 10 ? (
            <>
              <tr>
                <td>{rateData.userPos[rateType].position}.</td>
                <td>{rateData.userPos[rateType].login}</td>
                <td>
                  <TimeEnd
                    showMs={true}
                    time={rateData.userPos[rateType].time}
                  />
                </td>
                <td>{rateData.userPos[rateType].damage}</td>
              </tr>
            </>
          ) : null}
        </tbody>
      </table>
      <div className={`${styles.MapNavigationActionBar}`}>
        <div
          className={`${styles.MapNavigationCloseMap} BtnWithPressed`}
          onClick={() => {
            onClose();
          }}
        >
          <FontAwesomeIcon icon={faCircleLeft} size="lg" />
        </div>
      </div>
      <div
        className={`${styles.MapNavigationGroup}`}
        style={{ width: "140px" }}
      >
        <div
          className={`${styles.MapNavigationItem} BtnWithPressed`}
          onClick={() => {
            changeRateType("single");
          }}
        >
          <FontAwesomeIcon icon={faCircleInfo} size="lg" />
        </div>
        <div
          className={`${styles.MapNavigationItem} BtnWithPressed`}
          onClick={() => {
            changeRateType("single");
          }}
        >
          <FontAwesomeIcon icon={faUser} size="lg" />
        </div>
        <div
          className={`${styles.MapNavigationItem} BtnWithPressed`}
          onClick={() => {
            changeRateType("multiple");
          }}
        >
          <FontAwesomeIcon icon={faUsers} size="lg" />
        </div>
      </div>
    </div>
  );
}

function MapLevelSettings({ groupData, onClose }: any) {
  const starIcons = [
    { chance: 80, level: 1 },
    { chance: 100, level: 2 },
    { chance: 150, level: 3 },
    { chance: 200, level: 4 },
  ];
  const [gData, changeGroupData]: any = useState(groupData);
  const [socket]: any = useState(socketConnection());

  useEffect(() => {
    socket.on("update group", (resp: IRequestResponse) => {
      if (resp.hasError) return;
      const groupData = resp.getData();
      changeGroupData(groupData);
    });

    return () => {
      socket.off("update group");
    };
  }, []);

  return (
    <div className={styles.MapStartPanelContainer}>
      <div className={styles.MapStartPanel}>
        <div
          className={"close-modal Close BtnWithPressed"}
          onClick={() => onClose(false)}
        ></div>
        {/* <h2 className={styles.MapStartPanelTitle}>Волшебный лес</h2> */}
        <div className={styles.MapStartGroup}>
          {/* <div className="ItemsList">
            {new Array(4).fill(null).map((us, index) => {
              const userData = gData.members[index];

              return (
                <div key={index} className={`${styles.MapStartGroupItem} Item`}>
                  <MapAddUser user={userData} />
                </div>
              );
            })}
          </div> */}
          <div className={styles.MapStartGroupLevel}>
            {/* <h2 className={styles.MapStartGroupLevelStarsTitle}>Сложность</h2> */}
            <h2 className={styles.MapStartPanelTitle}>Сложность</h2>
            <div className={styles.MapStartGroupLevelStars}>
              {starIcons.map((star, index) => (
                <div key={index}>
                  <FontAwesomeIcon
                    key={index}
                    className={`${
                      star.level <= gData.difficulty
                        ? styles.MapStartGroupLevelSet
                        : null
                    }`}
                    icon={faStar}
                    onClick={() => {
                      socket.send("update group", {
                        difficulty: star.level,
                      });
                    }}
                  />
                </div>
              ))}
            </div>
            <div className={styles.MapTitleChance}>
              Шанс выпадения предметов:{" "}
              <span>{starIcons[gData.difficulty - 1].chance}%</span>
            </div>
          </div>
          <div
            className={`${styles.MapStartBtn} BtnWithPressed`}
            onClick={() => {
              socket.send("start group");
              onClose(true);
            }}
          >
            Начать
          </div>
        </div>
      </div>
    </div>
  );
}

function Map({ dungeonLevel, groupData, onClose }: any) {
  const { isModalOpened } = useSelector((store: any) => store.other);
  const [dungeonId, changeLevel]: any = useState(0);
  const [selectMap, changeSelectedMap]: any = useState(false);
  const [socket]: any = useState(socketConnection());
  const [gData, changeGroupData]: any = useState(groupData);
  const [modules, changeModules]: any = useState({});
  const [availableDungeons, changeDungeon]: any = useState(null);

  useEffect(() => {
    let { dungeons } = getFromCache("dungeons")[dungeonLevel];
    store.dispatch(setIsModalOpenedState(true));

    dungeons = Object.values(Object.assign({}, dungeons));

    changeDungeon(dungeons);
    if (groupData && groupData.state === "start") {
      changeSelectedMap(true);
      changeLevel(groupData.levelId);
    } else {
      changeLevel(dungeons[0].dungeonId);
    }

    socket.on("create group", (resp: IRequestResponse) => {
      if (resp.hasError) return;
      const groupData = resp.getData();
      changeGroupData(groupData);
      changeSelectedMap(true);
    });

    return () => {
      socket.off("create group");
      store.dispatch(setIsModalOpenedState(false));
    };
  }, []);

  function getDangeonId(isNext: boolean) {
    let nDungeonId = dungeonId;

    availableDungeons.forEach((dungeon: any, idx: number) => {
      if (dungeon.dungeonId === dungeonId) {
        if (isNext) {
          if (!availableDungeons[idx + 1]) return;
          nDungeonId = availableDungeons[idx + 1].dungeonId;
        } else {
          if (!availableDungeons[idx - 1]) return;
          nDungeonId = availableDungeons[idx - 1].dungeonId;
        }
      }
    });

    return nDungeonId;
  }

  function closeModal() {
    onClose();
    changeLevel(null);
    changeSelectedMap(false);
    changeGroupData(null);
    changeDungeon(null);
  }

  return (
    <div className={styles.Map}>
      <div className={styles.MapContainer}>
        <div className={styles.MapInfoBar}>
          <div
            className={styles.MapInfoBack + " mr-2"}
            onClick={() => {
              closeModal();
            }}
          >
            <FontAwesomeIcon icon={faAngleLeft} size="lg" />
          </div>
          <div className={styles.MapTitle}>
            <h3>{mapLevels[dungeonId].name}</h3>
          </div>
        </div>

        <div
          className={styles.MapContainerSelectDangeon}
          style={{
            background: mapLevels[dungeonId].image,
          }}
        ></div>

        {selectMap && (
          <MapLevelSettings
            groupData={gData}
            onClose={(isStarted: boolean) => {
              changeSelectedMap(null);
              if (isStarted) {
                closeModal();
              } else {
                socket.send("exit group");
              }
            }}
          />
        )}

        <div
          className={`${styles.MapEnterBtn} BtnWithPressed`}
          onClick={() => {
            socket.send("create group", {
              dungeonLevel: dungeonLevel,
              levelId: dungeonId,
            });
          }}
        >
          Начать
        </div>

        {modules.rate ? (
          <MapRate
            levelId={dungeonId}
            onClose={() => {
              changeModules((old: any) => {
                return { ...old, ["rate"]: false };
              });
            }}
          />
        ) : null}

        {/* <div className={`${styles.MapNavigationActionBar}`}>
          <div
            className={`${styles.MapNavigationCloseMap} BtnWithPressed`}
            onClick={() => {
              closeModal();
            }}
          >
            <FontAwesomeIcon icon={faCircleLeft} size="lg" />
          </div>
          <div
            className={`${styles.MapNavigationCloseMap} BtnWithPressed`}
            onClick={() => {
              closeModal();
            }}
          >
            <FontAwesomeIcon icon={faBook} size="lg" />
          </div>
          <div
            className={`${styles.MapNavigationCloseMap} BtnWithPressed`}
            onClick={() => {
              changeModules((old: any) => {
                return { ...old, ["rate"]: true };
              });
            }}
          >
            <FontAwesomeIcon icon={faListOl} size="lg" />
          </div>
        </div> */}
        <div className={`${styles.MapNavigationGroup}`}>
          <div
            className={`${styles.MapNavigationItem} BtnWithPressed`}
            onClick={() => {
              changeLevel(getDangeonId(false));
            }}
          >
            <FontAwesomeIcon icon={faAngleLeft} size="lg" />
          </div>
          <div
            className={`${styles.MapNavigationItem} BtnWithPressed`}
            onClick={() => {
              changeLevel(getDangeonId(true));
            }}
          >
            <FontAwesomeIcon icon={faAngleRight} size="lg" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Map;
