import { useEffect, useState } from "react";
import styles from "./ChestLocation.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBoltLightning,
  faCircleLeft,
  faLeftLong,
  faPerson,
  faPlus,
  faRefresh,
  faRightLong,
  faStar,
} from "@fortawesome/free-solid-svg-icons";
import {
  IRequestResponse,
  socketConnection,
} from "../../../../core/api/common";
import ItemsPager, { ItemCell } from "../ItemsPager/ItemsPager";

function ChestLocation({ onClose }: any) {
  const [socket]: any = useState(socketConnection());
  const [items, setItems]: any = useState([]);

  useEffect(() => {
    socket.on("get group items", (resp: IRequestResponse) => {
      if (resp.hasError) return;

      const items = resp.getData();
      setItems(items);
    });

    socket.emit("get group items");

    return () => {
      socket.off("get group items");
    };
  }, []);

  return (
    <div className={styles.ChestLocation}>
      <div className={styles.ChestContainer}>
        <h2 className={styles.Title}>Сокровища группы</h2>
        <div className={`${styles.ChestItems}`}>
          <ItemsPager
            items={items}
            pageCount={25}
            showLock={false}
            type={"chest"}
          />
        </div>
        <div>
          <div
            className={`${styles.CloseChest} BtnWithPressed`}
            onClick={() => {
              onClose();
            }}
          >
            <FontAwesomeIcon icon={faCircleLeft} size="lg" />
          </div>
          <div
            className={styles.RefreshChest}
            onClick={() => {
              socket.emit("get group items");
            }}
          >
            <FontAwesomeIcon icon={faRefresh} size="lg" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ChestLocation;
