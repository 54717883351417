import {
  faClose,
  faHandBackFist,
  faHandSparkles,
  faToolbox,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import styles from "./ItemInfo.module.scss";
import store from "../../../../services/store.service";
import { useSelector } from "react-redux";
import { socketConnection } from "../../../../core/api/common";
import { getTranslateValue } from "../../../../services/base/translate";
import { setTempQuickItemPane } from "../../../../services/store/other/other.store";
import { selectItem } from "../../../../services/store/items/items.store";
import { ItemCell } from "../ItemsPager/ItemsPager";
import React from "react";
import GetProps from "../GetProps/GetProps";
import ImproveItemModal from "../ImproveItemModal/ImproveItemModal";
import ActionModalWithButtons from "../ActionModalWithButtons/ActionModalWithButtons";

function ItemInfo() {
  const { selectedItem } = useSelector((store: any) => store.items);
  const { items } = useSelector((store: any) => store.items).data;

  const [socket]: any = useState(socketConnection());
  const [improveItem, changeImproveItemId]: any = useState(null);

  const btns = [
    {
      action: () => {
        socket.send("use item", selectedItem.inventoryId);
        close();
      },
      title: "Использовать",
    },
    {
      action: () => {
        store.dispatch(setTempQuickItemPane(selectedItem.inventoryId));
        close();
      },
      title: "Закрепить",
    },
    {
      action: () => {
        changeImproveItemId(selectedItem.item);
      },
      title: "Усилить",
    },
    {
      action: () => {
        socket.send("delete item", selectedItem.inventoryId);
        close();
      },
      title: "Выбросить",
    },
  ];

  function close() {
    store.dispatch(selectItem(null));
  }

  return (
    <>
      {selectedItem ? (
        <div className={styles.BackpackInfoContainer}>
          <ActionModalWithButtons
            buttons={btns}
            onClose={() => {
              close();
            }}
          >
            <div className={`${styles.BackpackInfo}`}>
              <div className={styles.BackpackInfoItem}>
                <div className={`${styles.BackpackInfoItemCell} ItemsList`}>
                  <div className="Item DisableHover">
                    <ItemCell
                      item={selectedItem.item}
                      withCount={false}
                      withLevel={true}
                    />
                  </div>
                </div>

                <div className={styles.BackpackInfoItemParams}>
                  <GetProps item={selectedItem.item}></GetProps>
                </div>
              </div>
            </div>
          </ActionModalWithButtons>
          {improveItem ? (
            <ImproveItemModal
              item={improveItem}
              onClose={() => {
                changeImproveItemId(null);
              }}
            />
          ) : null}
        </div>
      ) : null}
    </>
  );

  return (
    <>
      {selectedItem ? (
        <div className={styles.BackpackInfoContainer}>
          <div className={styles.BackpackInfoWrapper}>
            <div
              className={`${styles.BackpackInfo}`}
              onClick={(event: any) => {
                event.stopPropagation();
              }}
            >
              <FontAwesomeIcon
                className={styles.CloseModal}
                icon={faClose}
                onClick={() => {
                  close();
                }}
              />
            </div>

            {/* Кнопки */}
            <div className={styles.ActionButton}>
              {btns.map((button: any, idx: any) => {
                return (
                  <button
                    key={idx}
                    onClick={e => {
                      e.stopPropagation();
                      if (button.action) button.action();
                    }}
                  >
                    {button.title}
                  </button>
                );
              })}
            </div>
          </div>
          {improveItem ? (
            <ImproveItemModal
              item={improveItem}
              onClose={() => {
                changeImproveItemId(null);
              }}
            />
          ) : null}
        </div>
      ) : null}
    </>
  );
}

export default React.memo(ItemInfo);
