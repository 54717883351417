import { useEffect, useRef, useState } from "react";
import { random } from "../../../../services/base/list";
import styles from "./ItemDrop.module.scss";

function ItemDrop({ items }: any) {
  const [itemsList, changeItems]: any = useState({});
  const elRef: any = useRef();
  const [isEnd, changeEnd]: any = useState(false);

  useEffect(() => {
    const size = 64;
    const animationTimings = [350, 500, 3250]; // Временные интервалы для анимаций

    const startAnimation = () => {
      changeItems(
        items.reduce((acc: any, itemId: number, idx: number) => {
          acc[idx] = {
            opacity: 1,
            transform: `scale(0.75) translate(${0}px, ${0}px)`,
            transition: "all 0.05s linear",
          };
          return acc;
        }, {}),
      );
    };

    const middleAnimation = () => {
      changeItems(
        items.reduce((acc: any, itemId: number, idx: number) => {
          const isPositive = idx % 2 === 0;
          const rX = isPositive
            ? random(-size, -size / 2)
            : random(size / 2, size);
          const rY = random(-size, size);

          acc[idx] = {
            animate: "animate-of-dropped-item 0.4s linear",
            opacity: 1,
            transform: `scale(0.75) translate(${rX}px, ${rY}px)`,
            transition: "all 0.3s linear",
          };
          return acc;
        }, {}),
      );
    };

    const endAnimation = () => {
      if (!(items && items.length)) return;
      const ref = elRef?.current;
      const toolbarBackPack = document.getElementById("icon-chest");
      let diffX = 385;
      let diffY = 620;

      const refBounding = ref?.getBoundingClientRect();

      if (toolbarBackPack && toolbarBackPack?.parentElement && ref) {
        const toolbarBounding = toolbarBackPack.getBoundingClientRect();
        diffX = toolbarBounding.left - refBounding.left;
        diffY = toolbarBounding.top - refBounding.top;
      }

      changeItems(
        items.reduce((acc: any, itemId: number, idx: number) => {
          acc[idx] = {
            opacity: 0,
            transform: `translate(${diffX}px, ${diffY}px)`,
            transition: "all 0.45s ease-in",
          };
          return acc;
        }, {}),
      );

      if (items && items.length) {
        setTimeout(() => {
          toolbarBackPack?.classList.add("scale-bounce");
          setTimeout(() => {
            toolbarBackPack?.classList.remove("scale-bounce");
          }, 400);
        }, 100);

        setTimeout(() => {
          changeEnd(true);
        }, 500);
      }
    };

    const animateSequence = () => {
      setTimeout(startAnimation, animationTimings[0]);
      setTimeout(middleAnimation, animationTimings[1]);
      setTimeout(endAnimation, animationTimings[2]);
    };

    animateSequence(); // Запуск анимации

    return () => {
      animationTimings.forEach(clearTimeout); // Очистка всех таймеров
    };
  }, []);

  return (
    <div
      ref={elRef}
      className={styles.ItemDropContainer}
      style={{
        animation: "bounceAnimation 0.8s linear",
      }}
    >
      {(isEnd ? [] : items).map((item: any, idx: number) => {
        return (
          <div key={idx}>
            <div
              className={`${styles.ItemDrop} items items-${item}`}
              style={itemsList[idx]}
            ></div>
            <div
              className={`${styles.ItemDrop} ${styles.ItemDropFilter} items items-${item}`}
              style={itemsList[idx]}
            ></div>
          </div>
        );
      })}
    </div>
  );
}

export default ItemDrop;
