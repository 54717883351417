import { useEffect, useState, ReactNode } from "react";
import ReactDOM from "react-dom";
import styles from "./FullModal.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import { setIsModalOpenedState } from "../../../../services/store/other/other.store";
import store from "../../../../services/store.service";

interface ModalChatProps {
  title: string;
  children: ReactNode;
  buttons?: any;
  buttonClick?: any;
  onClose: () => void;
}

function FullModal({ buttons, children, onClose, title }: ModalChatProps) {
  const el: any = document.getElementById("portal-root");
  useEffect(() => {
    store.dispatch(setIsModalOpenedState(true));

    return () => {
      store.dispatch(setIsModalOpenedState(false));
    };
  }, []);

  return ReactDOM.createPortal(
    <div className={styles.FullModalContainer}>
      <div className={styles.FullModalHeader}>
        <div
          className={styles.FullModalBack}
          onClick={() => {
            onClose();
          }}
        >
          <FontAwesomeIcon icon={faAngleLeft} />
        </div>
        <h3>{title}</h3>
      </div>
      <div className={`${styles.FullModalContent} bkg-modal`}>{children}</div>
      {buttons ? (
        <div className={styles.FullModalButtons}>
          {buttons.list.map((btn: any, idx: number) => {
            return (
              <div
                key={`${btn.type} ${idx}`}
                className={`${styles.FullModalButton} ${
                  buttons.active === btn.type
                    ? styles.FullModalButtonActive
                    : ""
                }`}
                onClick={() => {
                  buttons.onClick(btn.type);
                }}
              >
                {btn.icon}
              </div>
            );
          })}
        </div>
      ) : null}
    </div>,
    el,
  );
}

export default FullModal;
