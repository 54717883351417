export function getTimeDiff(
  timeDifference: number,
  toFixedSize = 0,
  hideSecondsIfMoreMinute = false,
) {
  let results = "";
  if (timeDifference > 0) {
    const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
    const hours = Math.floor(
      (timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60),
    );
    const minutes = Math.floor(
      (timeDifference % (1000 * 60 * 60)) / (1000 * 60),
    );

    if (days > 0) results += days + "d ";
    if (hours > 0) results += hours + "h ";
    if (minutes > 0) results += minutes + "m ";

    if (!(hideSecondsIfMoreMinute && minutes > 0)) {
      results +=
        ((timeDifference % (1000 * 60)) / 1000).toFixed(toFixedSize) + "s";
    }
  }

  return results;
}

export function getTimeEffect(timeDifference: number) {
  const result = getTimeDiff(timeDifference);
  return result;
}

export function getTimeEnd(time: number, showMs = false) {
  if (time < 0) time = 0;

  const hours = Math.floor(time / 60000 / 60);
  const minutes = Math.floor((time / 60000) % 60);
  const seconds = Math.floor((time / 1000) % 60);
  const milliseconds = Math.floor(time % 1000);

  const formattedHours = hours < 10 ? `0${hours}` : `${hours}`;
  const formattedMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;
  const formattedSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`;
  const formattedMilliseconds =
    milliseconds < 10
      ? `00${milliseconds}`
      : milliseconds < 100
      ? `0${milliseconds}`
      : `${milliseconds}`;

  if (showMs) {
    return `${formattedMinutes}:${formattedSeconds}.${formattedMilliseconds}`;
  } else {
    return `${formattedMinutes}:${formattedSeconds}`;
  }
}
