import { useEffect, useRef, useState } from "react";
import styles from "./Chat.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import store from "../../../../services/store.service";

import {
  faClose,
  faPaperPlane,
  faCalendarDays,
  faEnvelope,
  faUsers,
  faBuildingShield,
  faComments,
  faDungeon,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import {
  IRequestResponse,
  socketConnection,
} from "../../../../core/api/common";
import { toggleChat } from "../../../../services/store/other/other.store";
import FullModal from "../FullModal/FullModal";

const chatTypes: any = [
  { icon: <FontAwesomeIcon icon={faComments} />, isActive: true, type: "all" },
  { icon: <FontAwesomeIcon icon={faDungeon} />, type: "dungeon" },
  { icon: <FontAwesomeIcon icon={faBuildingShield} />, type: "guild" },
  { icon: <FontAwesomeIcon icon={faUsers} />, type: "group" },
  { icon: <FontAwesomeIcon icon={faEnvelope} />, type: "personal" },
  { icon: <FontAwesomeIcon icon={faCalendarDays} />, type: "events" },
];

function MessageItem({ message }: any) {
  const [socket]: any = useState(socketConnection());

  if (!message.user) return null;
  return (
    <div
      className={`${styles.ChatInfoMessagesItem} ${
        message.type ? styles[`MessageItemType-${message.type}`] : ""
      }`}
    >
      <span className={styles.ChatInfoMessagesItemName}>
        [{message.user.username}]:{" "}
      </span>
      {message.group ? (
        <span>
          Приглашает вас в группу{" "}
          <span
            onClick={() => {
              socket.emit("join group", message.group.groupId);
            }}
          >
            <FontAwesomeIcon
              className={styles.ChatAddToGroupBtn}
              icon={faPlus}
            />
          </span>
        </span>
      ) : (
        <span>{message.message}</span>
      )}
    </div>
  );
}

interface HorizontalLoaderProps {
  loading: boolean;
}

const HorizontalLoader: React.FC<HorizontalLoaderProps> = ({ loading }) => {
  return (
    <div className={`horizontal-loader ${loading ? "show" : ""}`}>
      <div className="loader"></div>
    </div>
  );
};

function Chat({ onClose }: any) {
  const buttons: any = chatTypes.slice();
  const [chatType, setChatType]: any = useState(chatTypes[0].type);
  const [messages, setMessages]: any = useState({});
  const [messageCount, changeMessageCount]: any = useState({});
  const [isLoading, changeLoading]: any = useState(true);

  const [message, changeMsg]: any = useState("");

  const [socket]: any = useState(socketConnection());

  useEffect(() => {
    socket.emit("has new messages");

    socket.on("has new messages", (hasAnyMessage: any) => {
      if (hasAnyMessage.hasError) return;
      changeMessageCount(hasAnyMessage.getData());

      loadNewMessages(chatType);
    });

    socket.on("load messages", (msgResponse: IRequestResponse) => {
      if (msgResponse.hasError) return;
      changeLoading(false);

      const msgData = msgResponse.getData();
      const msgByType = messages[msgData.type] ? messages[msgData.type] : [];

      setMessages({
        ...messages,
        [msgData.type.type]: [...msgByType, ...msgData.messages],
      });
    });

    return () => {
      socket.off("load messages");
      socket.off("has new messages");
    };
  }, []);

  function loadNewMessages(type: string) {
    changeLoading(true);
    socket.emit("load messages", type);
  }

  return (
    <FullModal
      buttons={{
        active: chatType,
        list: buttons,
        onClick: (type: string) => {
          setChatType(type);
        },
      }}
      onClose={() => {
        store.dispatch(toggleChat(false));
      }}
      title="Сообщения"
    >
      <div>111 {chatType}</div>
    </FullModal>
  );

  return (
    <div className={styles.Chat}>
      <div className={styles.ChatContainer}>
        <div className={`${styles.ChatContainerBar}`}>
          <div
            className={styles.ChatContainerBack}
            onClick={() => {
              store.dispatch(toggleChat(false));
            }}
          >
            <FontAwesomeIcon
              className={styles.ChatContainerBackIcon}
              icon={faClose}
            />
          </div>

          <div className={styles.ChatTitle}>
            <h2>{chatTypes[chatType]?.label || "Сообщения"}</h2>
          </div>
        </div>
        <div>
          <div className={styles.ChatInfo}>
            <div className={styles.ChatInfoMessages}>
              <HorizontalLoader loading={isLoading} />

              {messages[chatType] && messages[chatType].length ? (
                messages[chatType].map((msg: any, idx: number) => {
                  return <MessageItem key={idx} message={msg} />;
                })
              ) : (
                <>
                  <h2 className={styles.ChatInfoNoData}>Нет сообщений</h2>
                </>
              )}
            </div>
            <div>
              <input
                className={styles.ChatInput}
                onChange={(e: any) => {
                  changeMsg(e.target.value);
                }}
                type="text"
                value={message}
              />
              <button
                className={styles.ChatBtn}
                onClick={() => {
                  socket.send("send message", {
                    message,
                    type: "chat",
                  });
                  changeMsg("");
                  socket.emit("load messages");
                }}
              >
                <div>
                  <FontAwesomeIcon icon={faPaperPlane} />
                </div>
              </button>
            </div>
          </div>
        </div>
        <div className={styles.ChatGroup}>
          <div className={styles.ChatGroupList}>
            {chatTypes.map(({ icon, type }: any, index: number) => {
              return (
                <div
                  key={type}
                  className={`${styles.ChatGroupItem} ${
                    type === chatType ? styles.ChatGroupItemActive : ""
                  }`}
                  onClick={() => {
                    setChatType(type);
                    loadNewMessages(type);
                  }}
                >
                  {messageCount[type] ? (
                    <div className={styles.ChatGroupItemCount}>
                      {messageCount[type]}
                    </div>
                  ) : null}
                  <FontAwesomeIcon icon={icon} />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Chat;
