import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import styles from "./Skill.module.scss";
import {
  IRequestResponse,
  socketConnection,
} from "../../../../core/api/common";

function Skill() {
  const socket = socketConnection();
  const storage = useSelector((store: any) => store.other);

  const { params } = useSelector((store: any) => store.user);

  const [times, setNewTime]: any = useState({});

  useEffect(() => {
    let interval: any = null;
    socket.on("get skill time", (resp: IRequestResponse) => {
      if (resp.hasError) return;
      const times = resp.getData();
      setNewTime(times);

      if (interval) clearInterval(interval);

      interval = setInterval(() => {
        setNewTime((oldTime: any) => {
          const nTime: any = {};
          Object.keys(oldTime).forEach(skill => {
            nTime[skill] = oldTime[skill];
            nTime[skill].expire -= 1000;
            if (nTime[skill].expire < 0) nTime[skill].expire = 0;
          });
          return nTime;
        });
      }, 1000);
    });
    socket.send("get skill time");
    return () => {
      socket.off("get skill time");
      clearInterval(interval);
    };
  }, []);

  const useSkill = (skill: string) => {
    if (times[skill].expire === 0) socket.send("use skill", skill);
  };

  return (
    <div
      className={`${styles.Skill} ${
        storage.isAttacked ? "" : styles.SkillHidden
      }`}
    >
      {(params.skills || []).map((skill: string) => {
        return (
          <div
            key={skill}
            className={`${styles[skill]} ${styles.SkillItem} ${times[skill]} BtnWithPressed`}
            onClick={() => useSkill(skill)}
          >
            {times[skill] && times[skill].timeout ? (
              <div
                className={styles.SkillTime}
                style={{
                  height:
                    (times[skill].expire / times[skill].timeout) * 100 + "%",
                }}
              ></div>
            ) : null}
          </div>
        );
      })}
    </div>
  );
}

export default Skill;
