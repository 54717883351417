import { useEffect, useState } from "react";
import styles from "./GroupEndModal.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBoltLightning,
  faCircleLeft,
  faLeftLong,
  faPerson,
  faPlus,
  faRightLong,
  faStar,
} from "@fortawesome/free-solid-svg-icons";
import { socketConnection } from "../../../../core/api/common";
import ItemsPager from "../ItemsPager/ItemsPager";
import TimeEnd from "../TimeEnd/TimeEnd";
import { useSelector } from "react-redux";
import store from "../../../../services/store.service";
import { setGroupEnd } from "../../../../services/store/other/other.store";

const REASONS: any = {
  timeOut: "Время вышло",
};
function GroupEndModal() {
  const [socket]: any = useState(socketConnection());
  const { groupEnd } = useSelector((store: any) => store.other);

  // useEffect(() => {
  //   // console.log("groupEnd", groupEnd);
  // }, [groupEnd]);

  return (
    <>
      {groupEnd ? (
        <div className={styles.GroupEndModal}>
          <div
            className={`${styles.GroupEndModalPanelTitle} ${
              !groupEnd.isFinished ? styles.lose : ""
            }`}
          ></div>

          <div
            className={`${styles.GroupEndModalPanel} ${
              !groupEnd.isFinished ? styles.GroupEndModalPanelLose : ""
            }`}
          >
            <div
              className={"close-modal BtnWithPressed"}
              onClick={() => {
                store.dispatch(setGroupEnd(null));
                socket.send("update info");
              }}
            ></div>
            {groupEnd.isFinished ? (
              <div className={styles.GroupEndModalGroup}>
                <div
                  className={`${styles.GroupEndModalGroupTime} ${styles.newRecord}`}
                >
                  <TimeEnd showMs={true} time={groupEnd.time}></TimeEnd>
                </div>
                <ItemsPager
                  items={groupEnd.rewards}
                  pageCount={12}
                  showLock={false}
                  type={"chest"}
                ></ItemsPager>
              </div>
            ) : (
              <div>
                <div className={styles.titleLose}>
                  {REASONS[groupEnd.reason]}
                </div>
              </div>
            )}
          </div>
        </div>
      ) : null}
    </>
  );
}

export default GroupEndModal;
